@import "styles/color.scss";

.infoText {
  width: 336px;

  h1 {
    color: $WHITE;
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  p {
    color: #969696;
    margin-bottom: 40px;
  }
}

.emailWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  input:disabled {
    color: #969696;
  }

  .lockIcon {
    position: absolute;
    color: #c7c7c7;
    top: var(--spacing-sm-4);
    right: var(--spacing-xs-4);
  }
}

.passwordWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  .showPasswordButton {
    color: #c7c7c7;
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: right;
    text-transform: uppercase;
    position: absolute;
    top: var(--spacing-sm-5);
    right: var(--spacing-xs-2);
    user-select: none;
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;


    &:hover {
      cursor: pointer;
    }
  }
}

.formContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 336px;

  .inputField,
  .passwordWrap .inputField {
    border: 2px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 0px #ffffff;
    height: 36px;
    width: auto;
    outline: none;
    padding: 0 10px 0 10px;
    margin-bottom: 4px;

    &:focus-visible {
      border: 2px solid $STRAIN_BLUE;
      outline: none;
    }
  }

  .inputFieldInvalid {
    background: rgba(255, 0, 38, 0.2);
    box-shadow: inset 0px -1px 0px #ff0026;
  }

  .invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: 16px;
    margin-bottom: 12px;
  }

  input,
  select,
  textarea {
    color: $WHITE;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
  }

  .link {
    color: $WHITE;
  }

  .inputFieldLabel {
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: contents;

    .inputField {
      margin-top: 4px;
    }

    margin: 0 0 6px 0;
    width: auto;
    text-align: left;

    color: rgba(255, 255, 255, 0.5);
  }

  button {
    margin-top: 15px;
  }

  .loginOptions {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);

    .staySignedIn {
      display: flex;
      align-items: center;
      label {
        font-family: var(--whoop-font-family-normal);
        font-size: var(--whoop-font-size-m);
        letter-spacing: normal;
        text-transform: none;
        margin: 2px 0 2px 15px;
      }
    }
    .checkbox {
      -webkit-appearance: none;
      background-color: #c7c7c7;
      width: 15px;
      height: 15px;
      position: relative;
      border-radius: 2px;
      cursor: pointer;
      &:checked::after {
        content: url("../assets/checkmark-icon.svg");
        font-size: 14px;
        position: absolute;
        top: -1px;
        left: -1px;
      }
    }
    .forgotPassword {
      font-family: var(--whoop-font-family-normal);
      font-size: var(--whoop-font-size-m);
      text-transform: capitalize;
      text-decoration: underline;
      color: #ffffff;
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin: 0;
    }
  }
}
