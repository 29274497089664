@import "styles/color.scss";

.container {
  margin: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background: linear-gradient(
      0,
      rgba(0, 0, 0, 0) 15%,
      rgba(84, 103, 116, 0.4) 100%
    ),
    linear-gradient(0, rgba(13, 17, 19, 0.9) 0%, rgba(0, 0, 0, 0) 100%),
    rgba(26, 34, 39, 0.85);
}

.layer {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url(../assets/login-background.jpg);
    background-size: cover;
}

.children {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 24px;
  width: 336px;

  .logo {
    height: 100px;
    width: 300px;
  }

  .logoText {
    font-family: var(--whoop-font-family-bold);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.35em;
    text-align: center;
    text-transform: uppercase;
    color: $WHITE;
    opacity: 0.5;
  }
}
